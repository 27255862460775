html,
body,
#root, .App{
  height: 100%;
}
body {
  margin: 0;
  font-family: 'MyriadforFAZ-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

#loadImageContainer img:first-of-type{
  margin: auto;
  position: absolute;
  width: 10em;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}