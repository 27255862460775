.QrCodeScanner{
    height: 100%;
}
.QrCodeScanner .qr-code-frame{
    width: 80vw;
    position: absolute;
    top: 40%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    z-index: 800;
}
.QrCodeScanner section{
    overflow: unset !important;
    height: 100%;
}
.QrCodeScanner div{
    border: none !important;
    transform: translateY(30%);
    left: 10px !important;
    right: 10px;
    width: unset !important;
    height: 45vh!important;
    box-shadow: none !important;
}
.QrCodeScanner p{
    color: #110a35;
    position: absolute;
    bottom: 20%;
    left: 20px;
    right: 20px;
    z-index: 999;
}

@media screen and (orientation:landscape) {
    .QrCodeScanner .qr-code-frame{
        width: 25vw;
    }
}