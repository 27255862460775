.QrCodeBtnBack{
    position: absolute;
    bottom: 45px;
    left: 20px;
    width: 15vw;
    z-index: 500;
}
.QrCodeBtnBack img{
    width: 90%;
}

@media screen and (orientation:landscape) {
    .QrCodeBtnBack{
        width: 5vw;
    }
}