.LookingForTarget{
    color: #fff;
    width: 90vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 498;
}
.LookingForTarget p{
    font-family: 'MyriadforFAZ-Bold', sans-serif;
    font-size: 1.2em;
    line-height: 1.5em;
}
.LookingForTarget img{
    display:inline-block;
    width: 6em;
    margin: 0 auto;
}


@media screen and (orientation:landscape) {
    .LookingForTarget {
        padding: 8% 0;
    }
}