.OverlayScreen{
    background-color: rgba(0, 0, 0, .7);
    color: #FFFFFF;
    padding: 20vh 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 800;
}
.OverlayScreen p{
    font-family: 'MyriadforFAZ-Bold', sans-serif;
    font-size: 1.2em;
    line-height: 1.5em;
}

.OverlayScreen #fine-print{
    font-family: 'MyriadforFAZ-Bold', sans-serif;
    font-size: 0.9em;
    line-height: 1.5m;
}
.OverlayScreen button{
    background-color: #e61849;
    color: #FFFFFF;
    padding: 15px 40px;
    border: none;
    font-size: 1.2em;
    margin: 20px 0px 40px 0px;
}
.OverlayScreen button img{
    display:inline-block;
    width: 10px;
    margin-left: 10px;
    margin-top: -3px;
    vertical-align: middle;
}

@media screen and (orientation:landscape) {
    .OverlayScreen {
        padding: 10vh 0;
    }
}