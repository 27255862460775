.RotateOverlay{
    height: 100%;
}
.RotateOverlay img{
    width: 6em;
    position: absolute;
    bottom: 33px;
    left: 5px;
    display: block;
    z-index: 800;
}

.RotateOverlay div{
    border: none !important;
    transform: translateY(30%);
    left: 10px !important;
    right: 10px;
    width: unset !important;
    height: 45vh!important;
    box-shadow: none !important;
}