.external-link{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 499;
}
.external-link-btn-container{

    position: absolute;
    bottom: 35px;
    left: 20px;
}
.external-link-btn img{
    min-width: 44px;
    max-width: 100px;
}