.footer{
    background-color: #110a35;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 800;
}
.footer a{
    color: #FFFFFF;
    text-decoration: none;
    padding: 0 10px;
    font-size: 13px;
    line-height: 15px;
}
.footer a:first-child{
    border-right: 1px solid #FFFFFF;
}

@media screen and (orientation:landscape) {
    .footer a{
        font-size: 13px;
        line-height: 15px;
    }
}