.BallOverlay{
    height: 100%;
}
.BallOverlay img{
    width: 6em;
    position: absolute;
    bottom: 5%;
    left: 15px;
    display: block;
    z-index: 800;
}

.BallOverlay p{
    color: #E0E0E0;
    width: 30em;
    position: absolute;
    top: 5px;
    left: 16vw;
    display: block;
    z-index: 800;
    text-align: left;
    font-size: 1.0em;
    text-shadow: -1px -1px 0 #110A35, 1px -1px 0 #110A35, -1px 1px 0 #110A35, 1px 1px 0 #110A35;;
}

.BallOverlay div{
    border: none !important;
    transform: translateY(30%);
    left: 10px !important;
    right: 10px;
    width: unset !important;
    height: 45vh!important;
    box-shadow: none !important;
}


@media screen and (orientation:landscape) {
    .BallOverlay img{
        bottom: 10%;
    }

    .BallOverlay p{
        left: 9vw; 
    }
}