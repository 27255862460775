.qrCodeBtn{
    position: absolute;
    width: 6em;
    z-index: 500;
    padding: 0px;
    right: 3px;
    bottom: 29px;
}

.qrCodeBtn img{
    width: 100%;
}

@media screen and (min-width: 800px) {
    /* .qrCodeBtn{
        display: none;
    } */
}
