.logo{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 800;
    width: 15vw;
}
.logo img{
    width: 100%;
}

@media screen and (orientation:landscape) {
    .logo {
        width: 8vw;
    }
}