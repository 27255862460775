.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.cardplace {
  z-index: 10;
  position: absolute;
  top: 5%;
  width: 100%;
}

.card {
  margin: auto;
  width: 90%;
  height: 100%;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  background-color: white;
  color: #7611B7;
  font-family: 'Raleway', sans-serif;
  font-size: 3vh;
  vertical-align: middle;
  word-wrap: break-word;

  text-overflow: ellipsis;
  visibility: hidden;
}

canvas {
  display: block;
}

#overlay2d {
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#almostthereContainer{
  background-color: #FFFFFF;
}