.cookie{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
}
.cookie-container{
    position: absolute;
    bottom: 0;
    background-color: lightgray;
    padding: 20px 20px 40px 20px;
}
.cookie-container span{
    font-weight: 700;
}
.cookie-container a{
    display: inline-block;
    width: 49%;
    text-align: center;
    padding: 5px 0;
    text-transform: uppercase;
}