.ANZ_DHL{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 499;
}
.ANZ_DHL-btn-container{

    position: absolute;
    bottom: 60px;
    left: 20px;
}
.ANZ_DHL-btn{
    border: 2px solid #d40511;
    border-radius: 4px;
    color: #fff;
    background-color: #d40511;
    display: block;
    font-size: 1em;
    text-align: center;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    min-width: 44px;
    line-height: 1.25;
    padding: 9px 20px;
}